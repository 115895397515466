@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: white;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .5);
  border-radius: 8px;
}

/* @import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap"); */

.App {
  text-align: center;
}

.main {
  display: 'flex';
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .path {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  animation: dash 2s linear alternate;
  animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
} */

.arrow {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  -webkit-animation: draw 1s linear forwards;
          animation: draw 1s linear forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  stroke-linecap: round;
  fill: transparent;
  stroke: #ffac3f;
  stroke-width: 6;
  stroke-miterlimit: 10;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.arrow-head {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  -webkit-animation: draw 1s linear forwards;
          animation: draw 1s linear forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  stroke-linecap: round;
  fill: transparent;
  stroke: #ffac3f;
  stroke-width: 6;
  stroke-miterlimit: 10;
  -webkit-animation-delay: 1.0s;
          animation-delay: 1.0s;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.path {
  stroke-dasharray: 750;
  stroke-dashoffset: 750;
  -webkit-animation: draw 1s linear forwards;
          animation: draw 1s linear forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  stroke-linecap: round;
  fill: transparent;
  stroke: #ffac3f;
  stroke-width: 4;
  stroke-miterlimit: 10;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.path2 {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  stroke-linecap:round;
  stroke-width: 5;
  -webkit-animation: dash 3s linear alternate;
          animation: dash 3s linear alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.header {
  height: 0vh;
  border: 3px solid rebeccapurple
}

.container {
  /* hide fixed overflow contents */
  clip: rect(0, auto, auto, 0);

  /* does not work if overflow = visible */
  overflow: hidden;

  /* only works with absolute positioning */
  position: absolute;

  /* Make sure containers are full-width and height */
  height: 100vh;
  left: 0;
  width: 100%;
  
  /* safari hack */
  -webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%);
}

.container_solid {
  /* background: white; */
  
  /* position this container at the top of its parent element */
  top: 0;
}

.title_wrapper {
  position: fixed;
  display: block;  
  margin: auto;
  width: 100%;
  /* center the text wrapper vertically */
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.title_wrapper h1 {
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;
}

.container_solid .title_wrapper h1 {
  /* the text background */
  background-color: #56cac6;
  background-size: 100vw auto;
  background-position: center;
  
  /* clip the text is possible */
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  
  /* fallback text color */
  color: black;
}

.container_image {
  background-color: #56cac6;
  background-size:100vw auto;
  background-position: center;
  background-attachment: fixed;
  
  /* position the second container below the first container */
  top: 100vh;
}

.container_image .title_wrapper h1 {
  /* color: white; */
}

section {
  min-height: 100vh;
  padding: 2em;
  margin: auto;
  max-width: 800px;
}

section h2 {
  font-family: 'Fraunces', serif;
}

section p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

/* TreeView styles */

.node__root > circle {
  fill: red;
}

.node__branch > circle {
  fill: yellow;
}

.node__leaf > circle {
  fill: green
  /* Let's also make the radius of leaf nodes larger */
  /* r: 40; */
}

.custom-link {
  stroke-width: 0;
}

.custom-link-animation {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  -webkit-animation: dash 4s linear alternate;
          animation: dash 4s linear alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  /* animation: dash 5s linear; */
  stroke-width: 15;
  stroke: #ffac3f69 !important;
  /* stroke-dasharray: 350;
  stroke-dashoffset: 25; */
}

/* End of TreeView styles */

.body {
  align-items: center;
  justify-content: center;
}

.element {
  height: 50px;
  width: auto;
  padding: 0px 40px;
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.elementSlow {
  -webkit-animation: nudge 300s linear infinite alternate;
          animation: nudge 300s linear infinite alternate;
}

.elementMedium {
  -webkit-animation: nudge 400s linear infinite alternate;
          animation: nudge 400s linear infinite alternate;
}

.elementFast {
  -webkit-animation: nudge 500s linear infinite alternate;
          animation: nudge 500s linear infinite alternate;
}

@-webkit-keyframes nudge {
  0% {
    -webkit-transform: translate(2000px, 0);
            transform: translate(2000px, 0);
  }
  
  100% {
    -webkit-transform: translate(-10000px, 0);
            transform: translate(-10000px, 0);
  }
}

@keyframes nudge {
  0% {
    -webkit-transform: translate(2000px, 0);
            transform: translate(2000px, 0);
  }
  
  100% {
    -webkit-transform: translate(-10000px, 0);
            transform: translate(-10000px, 0);
  }
}

/* @keyframes pulse {
  0%, 100% {
    background-color: #ffac3f;
  }
  50% {
    background-color: #07B1AB;
  }
} */

/* ::-moz-selection {
  color: #FFFFFF;
  background: #4ed9d9;
}

::selection {
  color: #FFFFFF;
  background: #4ed9d9;
} */

.rd3t-svg {
  min-height: 700px !important;
}

/* .cursor {
  cursor: url("./cursor.png"), auto;
  border: 1px solid blue;
}

.cursors {
  border: 1px solid red
}

.cursors > .cursor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 30px 2px;
  text-align: center;  
} */

